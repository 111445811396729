import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/encrypt-utility";
//import commonAPIService from "../../common/js/common-api-service";
import adminService from "./admin-service";
export default {
  name: "PartComp",
  props: {
    name: String,
    compCalled: Boolean,
    companyId: String,
    userId: String,
    actionList: Array,
    partClassList: Array,
    businessTableData: Array,
    partId: Number
  },
  data: () => ({
    installId: EncryptUtility.localStorageDecrypt("installId"),
    isFormValid: false,
    disableField: false,
    showUpdate: false,
    businessSelected: [],
    required: [(v) => !!v || "Field is required"],
    qtyRule: [
      (v) => parseInt(v) > 0 || "Field should be greater than 0",
      (v) => parseInt(v) < 2147483647 || "Field should be less than 2,147,483,647",
    ],
    projectList: [{ project: "Oak Lane Partners", proj_key: 186 }],
    partObj: {
      user_id: "",
      part_key: 0,
      proj_key: 186,
      class_key: "",
      part_num: "",
      part_desc: "",
      isEnabled: true,
      bu_keys: [],
      project: "",
      class: "",
      available_qty: 0,
      isApproved:false,
    },
    partDetails: [],
    headerBuisness: [{
      text: 'Business Name',
      align: 'start',
      value: 'business',
    },]
  }),
  async mounted() {
    this.compCalled = true
    this.partObj.user_id = this.userId
    if (this.name == 'New Part') {
      this.disableField = false;
    }
    else {
      this.disableField = true;
      let partDetails = await adminService.getPartDetails("get", this.userId, this.partId, false)
      if (partDetails.length > 0) {
        console.log()
        this.partObj = {
          isApproved:partDetails[0].isApproved,
          user_id: this.userId,
          part_key: this.partId,
          project: partDetails[0].Project,
          class: partDetails[0].Class,
          proj_key: 186,   // Static or dynamic depending on use case
          class_key: partDetails[0].class_key,   // Initially empty, can be set later
          part_num: partDetails[0].Part_Number,
          part_desc: partDetails[0].Description,
          isEnabled: partDetails[0].IsActive,
          available_qty: partDetails[0].available_qty,
          bu_keys: partDetails[0]?.bu_ids.map(x =>
            x.bu_key
          )   // Initially empty, can be populated later
        };
        this.businessSelected = partDetails[0]?.bu_ids.map((x) => ({
          bu_id: x.bu_key,
          business: x.business
        }));
      }
    }


  },

  methods: {
    actionSetter(permission) {
      return Utility.actionSetter(this.actionList, permission)
    },
    async onClickSubmit() {
      if (this.$refs.partsForm.validate()) {
        this.partObj.bu_keys = this.businessSelected?.map(x =>
          x.bu_id)
        if (this.name == "New Part") {
          let submitObj = { json: JSON.stringify(this.partObj) }
          let submitRes = await adminService.postPartAdd("post", submitObj, true)
          submitRes == "Parts Added Successfully!" ? this.closeDialog() : ""
        }
        else {
          delete this.partObj.project;
          delete this.partObj.class;
          let submitObj = { json: JSON.stringify(this.partObj) }
          let submitRes = await adminService.postPartUpdate("post", submitObj, true)
          submitRes == "Parts Updated Successfully!" ? this.closeDialog() : ""
        }
      }
    },
    closeDialog() {
      this.compCalled = false;
      this.$emit("close");
      this.name = "";
      this.deleteBusinessDialog = false;
    },

    onClickEdit() {
      this.name = "Update Part";
      this.disableField = false;
    },
    onChangeIsEnabled(item){
      console.log(item)
      !item ?this.partObj.isApproved=false:""
    }

  },
};